@font-face {
  font-family: 'Kern';
  font-display: swap;
  src: url('../fonts/kern_bold.woff2') format('woff2'),
    url('../fonts/kern_bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Kern';
  font-display: swap;
  src: url('../fonts/kern_medium.woff2') format('woff2'),
    url('../fonts/kern_medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Kern';
  font-display: swap;
  src: url('../fonts/kern_regular.woff2') format('woff2'),
    url('../fonts/kern_regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Kern Standard';
  font-display: swap;
  src: url('../fonts/kern_standard.woff2') format('woff2'),
    url('../fonts/kern_standard.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Kern Cycle';
  font-display: swap;
  src: url('../fonts/kern_cycle.woff2') format('woff2'),
    url('../fonts/kern_cycle.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}