@import 'fonts';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --content-max-width: 100%;
  --space: 25px;

  @screen md {
    --content-max-width: 768px;
  }
  @screen lg {
    --content-max-width: 100%;
    --space: 5%;
  }
}

@layer base {
  html,
  body {
    @apply bg-black font-regular;
  }

  * {
    @apply antialiased;
  }
}

@layer utilities {
  .grid-overflow-container {
    display: grid;
    grid-template-columns: [full-start] 1fr [content-start] calc(min(var(--content-max-width), 100%) - var(--space)*2) [content-end] 1fr [full-end];
  }

  .grid-overflow-container > * {
    grid-column: content;
  }

  .grid-overflow-inner {
    align-items: flex-start;
    display: flex;
    gap: var(--space);
    grid-column: content;

    &::after {
      content: "";
      align-self: stretch;
      padding-inline-end: max(var(--space),(100vw - var(--content-max-width))/2 - var(--space));

      @screen lg {
        display: none;
      }
    }

    & > * {
      flex-shrink: 0;
      inline-size: 100%;
      max-inline-size: 80%;
      scroll-snap-align: start;

      @screen md {
        max-inline-size: calc(50% - var(--space)/2);
      }

      @screen lg {
        max-inline-size: 100%;
        flex-shrink: 1;
      }
    }
  }

  .grid-overflow {
    display: grid;
    grid-column: full !important;
    grid-template-columns: inherit;
    overflow-x: scroll;
    padding-block: var(--space);
    overscroll-behavior-x: contain;
    scroll-snap-type: x proximity;
    scroll-padding-inline: max(var(--space), (100vw - var(--content-max-width) + var(--space) * 2) / 2);

    @screen xl {
      scroll-padding-inline: max(var(--space), (100vw - var(--content-max-width)) / 2);
    }
  }
}